<card>
  <card-header>
    <div class="container" translate>MANAGE_ACCCESS_REQUEST.TITLE</div>
  </card-header>
  <card-body>
    <div class="container">
      <form [formGroup]="filterAccessRequest">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="name" translate
              >MANAGE_ACCCESS_REQUEST.HOME.FILTER.FULL_NAME</label
            >
            <input
              type="text"
              class="form-control form__input"
              id="requester_id"
              [ngbTypeahead]="searchRequester"
              [inputFormatter]="formatterRequester"
              [resultFormatter]="formatterRequester"
              (editable)="(false)"
              #input
              (selectItem)="setValueForm($event, 'requester_id')"
            />
            <div class="form-control-feedback"></div>
          </div>
          <div class="form-group col-md-4">
            <label for="cpf_passport" translate
              >MANAGE_ACCCESS_REQUEST.HOME.FILTER.CPF_PASSPORT</label
            >
            <input
              type="text"
              class="form-control form__input"
              formControlName="cpf_passport"
              id="cpf_passport"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="status" translate>burden_requests.status</label>
            <select
              formControlName="status"
              id="status"
              class="form-control form__input"
            >
              <option [ngValue]="null" disabled translate>
                generic.search.select
              </option>
              <option value="Aprovado" translate>
                reports.requester.approved
              </option>
              <option value="Pendente" translate>
                reports.requester.pending
              </option>
              <option value="Reprovado" translate>
                reports.requester.failed
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="country" translate
              >MANAGE_ACCCESS_REQUEST.HOME.FILTER.COUNTRY</label
            >
            <input
              type="text"
              class="form-control form__input"
              formControlName="country"
              id="country"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="corporative_email" translate
              >MANAGE_ACCCESS_REQUEST.HOME.FILTER.CORPORATIVE_EMAIL</label
            >
            <input
              type="email"
              class="form-control form__input"
              formControlName="corporative_email"
              id="corporative_email"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="company_name" translate
              >MANAGE_ACCCESS_REQUEST.HOME.FILTER.COMPANY_NAME</label
            >
            <input
              type="text"
              class="form-control form__input"
              formControlName="company_name"
              id="company_name"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-5">
            <label for="period__begin" class="lbl__thinner" translate>
              generic.search.start_period
            </label>
            <div class="input-group">
              <input
                type="text"
                formControlName="initDateInput"
                placeholder="{{ 'GENERIC.DATE_INPUT_START' | translate }}"
                class="form-control"
                bsDatepicker
                [bsConfig]="bsConfig"
              />
            </div>
          </div>
          <div class="col-md-2 d-flex justify-content-center">
            <label for="" translate>generic.search.until</label>
          </div>
          <div class="form-group col-md-5">
            <label for="period__end" class="lbl__thinner" translate>
              generic.search.end_period
            </label>
            <div class="input-group">
              <input
                type="text"
                formControlName="finishDateInput"
                placeholder="{{ 'GENERIC.DATE_INPUT_END' | translate }}"
                class="form-control"
                bsDatepicker
                [bsConfig]="bsConfig"
              />
            </div>
          </div>
        </div>
        <div class="form-row d-flex aling_end">
          <div class="form-group">
            <button
              class="btn btn-outline-primary col-md-4"
              (click)="reset($event, input)"
              translate
            >
              MANAGE_ACCCESS_REQUEST.HOME.FILTER.CLEAR
            </button>
            <button
              type="submit"
              class="btn btn-primary col-md-4"
              (click)="refreshPage()"
              translate
            >
              MANAGE_ACCCESS_REQUEST.HOME.FILTER.SEARCH
            </button>
          </div>
        </div>
      </form>
      <hr />
      <div class="table-responsive">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" translate>
                MANAGE_ACCCESS_REQUEST.HOME.TABLE.CODE
              </th>
              <th scope="col" translate>
                MANAGE_ACCCESS_REQUEST.HOME.TABLE.REQUEST_DATE
              </th>
              <th scope="col" translate>
                MANAGE_ACCCESS_REQUEST.HOME.TABLE.NAME
              </th>
              <th scope="col" translate>
                MANAGE_ACCCESS_REQUEST.HOME.TABLE.EMAIL
              </th>
              <th scope="col" translate>
                MANAGE_ACCCESS_REQUEST.HOME.TABLE.COUNTRY
              </th>
              <th scope="col" translate>
                MANAGE_ACCCESS_REQUEST.HOME.TABLE.COMPANY_NAME
              </th>
              <th scope="col" translate>manage_acccess_request.edit.status</th>
              <th scope="col" translate></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let accessRequest of accessRequests">
              <th scope="row">{{ accessRequest.cpf_passport }}</th>
              <td>{{ accessRequest.creationDate | date : "shortDate" }}</td>
              <td>{{ accessRequest.name }}</td>
              <td>{{ accessRequest.corporative_email }}</td>
              <td>{{ accessRequest.country }}</td>
              <td>{{ accessRequest.company_name }}</td>
              <td>
                {{ accessRequest.status.replace("Recusado", "Reprovado") }}
              </td>
              <td class="link">
                <a [routerLink]="['editar', accessRequest.id]" translate
                  >MANAGE_ACCCESS_REQUEST.HOME.TABLE.MANAGE</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex flex-column flex-lg-row justify-content-between p-2">
        <p>
          <label translate>GENERIC.PAGINATION.LABEL.SHOWING</label>
          <label>&nbsp; {{ currentPageSize }}</label
          >&nbsp; <label translate>GENERIC.PAGINATION.LABEL.REGISTERS</label>
          <label>&nbsp; {{ collectionSize }}</label>
        </p>
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [maxSize]="5"
          [rotate]="true"
          [pageSize]="pageSize"
          (pageChange)="refreshPage()"
          class="d-flex d-lg-block justify-content-center"
        >
        </ngb-pagination>
        <div class="d-flex justify-content-center">
          <select
            class="custom-select"
            style="width: auto"
            [(ngModel)]="pageSize"
            (ngModelChange)="refreshPage()"
          >
            <option [ngValue]="10">
              10
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="25">
              25
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="50">
              50
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
            <option [ngValue]="100">
              100
              <label translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
            </option>
          </select>
        </div>
      </div>
    </div>
  </card-body>
</card>
