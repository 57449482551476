<card class="mb-5">
  <card-header>
    <div class="container" translate>register.edit_data</div>
  </card-header>

  <card-body>
    <div class="container">
      <form [formGroup]="registerForm">
        <h6 class="subtitle__register" translate>
          MANAGE_ACCCESS_REQUEST.EDIT.DATA_INFORMATION
        </h6>
        <br />

        <div class="row requester-data-row">
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.full_name</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.full_name' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.name.errors &&
                  registerForm.controls.name.touched
              }"
              formControlName="name"
              id="name"
            />
            <div id="name" class="invalid-feedback" translate>
              register.required_field
            </div>
            <!-- <p>{{ requester?.name }}</p>-->
          </div>
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.cpf_passport</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.cpf_passport' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.cpf_passport.errors &&
                  registerForm.controls.cpf_passport.touched
              }"
              formControlName="cpf_passport"
              id="cpf_passport"
            />
            <div id="name" class="invalid-feedback" translate>
              register.required_field
            </div>
            <!-- <p>{{ requester?.cpf_passport }}</p> -->
          </div>
          <div class="col-md-4">
            <label translate
              >manage_acccess_request.edit.corporative_email</label
            >
            <input
              [disabled]="true"
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.corporative_email' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.corporative_email.errors &&
                  registerForm.controls.corporative_email.touched
              }"
              formControlName="corporative_email"
              id="corporative_email"
            />
            <div id="name" class="invalid-feedback" translate>
              register.required_field
            </div>
            <!-- <p>{{ requester?.corporative_email }} </p> -->
          </div>
        </div>
        <div class="row requester-data-row">
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.birth_date</label>
            <input
              [disabled]="true"
              type="date"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.birth_date' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.birth_date.errors &&
                  registerForm.controls.birth_date.touched
              }"
              formControlName="birth_date"
              id="birth_date"
            />
            <div id="name" class="invalid-feedback" translate>
              register.required_field
            </div>
            <!-- <p>{{ requester?.birth_date | date: "dd/MM/yyyy" }}</p> -->
          </div>
        </div>
        <hr />
        <h6 class="subtitle__register" translate>
          MANAGE_ACCCESS_REQUEST.EDIT.DATA_COMPANY
        </h6>
        <br />
        <div class="row requester-data-row">
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.company_name</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.company_name' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.company_name.errors &&
                  registerForm.controls.company_name.touched
              }"
              formControlName="company_name"
              id="company_name"
            />
            <div id="name" class="invalid-feedback" translate>
              register.company_name
            </div>
            <!-- <p>{{ requester?.company_name }}</p> -->
          </div>
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.role</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'manage_acccess_request.edit.role' | translate }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.role.errors &&
                  registerForm.controls.role.touched
              }"
              formControlName="role"
              id="role"
            />
            <div id="name" class="invalid-feedback" translate>
              register.role
            </div>
            <!-- <p>{{ requester?.role }}</p> -->
          </div>
          <div class="form-group col">
            <label translate
            >manage_acccess_request.edit.company_category</label
          >
            <select
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.company_category.errors &&
                  registerForm.controls.company_category.touched
              }"
              formControlName="company_category"
              id="company_category"
              class="form-control"
              placeholder="company_category"
            >
              <option
                *ngFor="let category of categories; let i = index"
                [value]="categories[i].id"
              >
                {{ categories[i].description }}
              </option>
            </select>
            <div
              id="company_category"
              class="invalid-feedback"
              translate
            >
              register.required_field
            </div>
          </div>
        </div>
        <div class="row requester-data-row">
          <div class="col-md-4">
            <label translate
              >manage_acccess_request.edit.corporative_cellphone</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.corporative_cellphone' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.corporative_cellphone.errors &&
                  registerForm.controls.corporative_cellphone.touched
              }"
              formControlName="corporative_cellphone"
              id="corporative_cellphone"
              [mask]="'+000000000000000'"
            />
            <div id="name" class="invalid-feedback" translate>
              register.corporative_cellphone
            </div>
            <!-- <p>{{ requester?.corporative_cellphone | mask: '+000000000000000' }}</p> -->
          </div>
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.company_website</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.company_website' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.company_website.errors &&
                  registerForm.controls.company_website.touched
              }"
              formControlName="company_website"
              id="company_website"
            />
            <div id="name" class="invalid-feedback" translate>
              register.company_website
            </div>
            <!-- <p>{{ requester?.company_website }}</p> -->
          </div>
          <div class="col-md-4">
            <label translate
              >manage_acccess_request.edit.corporative_telephone</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.corporative_telephone' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.corporative_telephone.errors &&
                  registerForm.controls.corporative_telephone.touched
              }"
              formControlName="corporative_telephone"
              id="corporative_telephone"
              [mask]="'+000000000000000'"
            />
            <div id="name" class="invalid-feedback" translate>
              register.corporative_telephone
            </div>
            <!-- <p>{{ requester?.corporative_telephone | mask: '+000000000000000' }}</p> -->
          </div>
        </div>
        <div class="row requester-data-row">
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.company_address</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.company_address' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.company_address.errors &&
                  registerForm.controls.company_address.touched
              }"
              formControlName="company_address"
              id="company_address"
            />
            <div id="name" class="invalid-feedback" translate>
              register.company_address
            </div>
            <!-- <p>{{ requester?.company_address }}</p> -->
          </div>
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.city</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'manage_acccess_request.edit.city' | translate }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.city.errors &&
                  registerForm.controls.city.touched
              }"
              formControlName="city"
              id="city"
            />
            <div id="name" class="invalid-feedback" translate>
              register.city
            </div>
            <!-- <p>{{ requester?.city }}</p> -->
          </div>
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.country</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.country' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.country.errors &&
                  registerForm.controls.country.touched
              }"
              formControlName="country"
              id="country"
            />
            <div id="name" class="invalid-feedback" translate>
              register.country
            </div>
            <!-- <p>{{ requester?.country }}</p> -->
          </div>
        </div>
        <div class="row requester-data-row">
          <div class="col-md-4">
            <label translate>manage_acccess_request.edit.zip_code</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.zip_code' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.zip_code.errors &&
                  registerForm.controls.zip_code.touched
              }"
              formControlName="zip_code"
              id="zip_code"
            />
            <div id="name" class="invalid-feedback" translate>
              register.zip_code
            </div>
            <!-- <p>{{ requester?.zip_code }}</p> -->
          </div>
          <div class="col-md-4" *ngIf="isCompanyBrazilian">
            <label>CNPJ</label>
            <p>{{ requester?.cnpj }}</p>
          </div>
        </div>
        <hr />
        <div class="row requester-data-row">
          <div class="col-md-12 requester-data-row">
            <h6 class="subtitle__register" translate>
              manage_acccess_request.edit.additional_information
            </h6>
          </div>
          <div class="col-md-4" *ngIf="requester?.name_complement">
            <label translate>register.full_name</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.name_complement' | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.name_complement.errors &&
                  registerForm.controls.name_complement.touched
              }"
              formControlName="name_complement"
              id="name_complement"
            />
          </div>
          <div
            class="col-md-4"
            *ngIf="requester?.corporative_telephone_complement"
          >
            <label translate>register.corporate_phone</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.corporative_telephone_complement'
                  | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.corporative_telephone_complement
                    .errors &&
                  registerForm.controls.corporative_telephone_complement.touched
              }"
              formControlName="corporative_telephone_complement"
              id="corporative_telephone_complement"
            />
          </div>
          <div class="col-md-4" *ngIf="requester?.corporative_email_complement">
            <label translate>register.corporate_email</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{
                'manage_acccess_request.edit.corporative_email_complement'
                  | translate
              }}"
              [ngClass]="{
                'is-invalid':
                  registerForm.controls.corporative_email_complement.errors &&
                  registerForm.controls.corporative_email_complement.touched
              }"
              formControlName="corporative_email_complement"
              id="corporative_email_complement"
            />
          </div>
        </div>
        <hr />
        <h6 class="subtitle__register" translate>
          manage_acccess_request.edit.bond_file
        </h6>
        <br />
        <div class="row gap-2">
          <input
            style="display: none"
            type="file"
            (change)="onFileSelected($event)"
            id="fileDropRef"
            #fileInput
          />
          <div
            class="col-md-12 p-2"
            (click)="fileInput.click()"
            *ngIf="fileName === ''; else elseFile"
          >
            <div class="border-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#084C7D"
                class="bi bi-upload col-md-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                />
                <path
                  d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
                />
              </svg>
              <div class="col-md-11">
                <p translate>register.insert_bond_letter</p>
              </div>
            </div>
          </div>
          <ng-template #elseFile>
            <div class="col-md-12 p-2">
              <label class="normal__label download">
                <a [href]="requester?.company_bond_file" target="_blank">
                  <div class="col-md-12">
                    <app-download
                      type="file-pdf"
                      [direction]="'row'"
                      [label]="fileName"
                      size="30px"
                    >
                    </app-download>
                  </div>
                </a>
                <fa-icon
                  *ngIf="fileName !== ''"
                  [icon]="['fas', 'trash']"
                  style="
                    color: #084c7d;
                    font-size: 20px;
                    cursor: pointer;
                    margin-left: 5px;
                  "
                  (click)="removeFile()"
                >
                </fa-icon>
              </label>
            </div>
          </ng-template>
          <!--          <div class="col-md-12 p-2">-->

          <!--            <div class="border-info">-->
          <!--              <svg-->
          <!--                xmlns="http://www.w3.org/2000/svg"-->
          <!--                width="20"-->
          <!--                height="20"-->
          <!--                fill="#084C7D"-->
          <!--                class="bi bi-upload col-md-2"-->
          <!--                viewBox="0 0 16 16"-->
          <!--              >-->
          <!--                <path-->
          <!--                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"-->
          <!--                />-->
          <!--              </svg>-->
          <!--              <div class="col-md-11">-->
          <!--                <p translate>manage_acccess_request.edit.reference_information</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <br />
        <!-- Appdownload -->
        <!--        <div-->
        <!--          class="row requester-data-row"-->
        <!--          *ngIf="-->
        <!--            requester?.company_bond_file && requester?.company_bond_file_name-->
        <!--          "-->
        <!--        >-->
        <!--          <div class="col-md-12">-->
        <!--            <div class="col-md-12">-->
        <!--              <label class="normal__label download">-->
        <!--                <a [href]="requester?.company_bond_file" target="_blank">-->
        <!--                  <div class="col-md-12">-->
        <!--                    <app-download-->
        <!--                      type="file-pdf"-->
        <!--                      [direction]="'row'"-->
        <!--                      [label]="requester?.company_bond_file_name"-->
        <!--                      size="30px"-->
        <!--                    >-->
        <!--                    </app-download>-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </label>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="lighter__label">
          <div class="row" *ngIf="statusRequest === 'Aprovado'">
            <div class="col-md-6">
              <label translate>manage_acccess_request.edit.status</label>
              <label>{{ statusRequest }}</label>
            </div>
            <div class="col-md-6">
              <label translate>manage_acccess_request.edit.approval_date</label>
              <p>{{ requester?.statusDate | date : "dd/MM/yyyy" }}</p>
            </div>
          </div>

          <div class="row" *ngIf="statusRequest === 'Reprovado'">
            <div class="col-md-6">
              <div class="col-md-12">
                <label translate>manage_acccess_request.edit.status</label>
                <label>{{ statusRequest }}</label>
              </div>
              <div class="col-md-12">
                <label translate
                  >manage_acccess_request.edit.disapproval_date</label
                >
                <p>{{ requester?.statusDate | date : "dd/MM/yyyy" }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <label translate>manage_acccess_request.edit.reason</label>
              <p>{{ requester?.reason_failure }}</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div class="form-group group__btns__register">
          <button
            class="btn btn__default__large"
            [routerLink]="['/home']"
            translate
          >
            generic.buttons.cancel</button
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="btn btn__primary" (click)="save()" translate>
            generic.buttons.send
          </button>
        </div>
      </form>
    </div>
  </card-body>
</card>
