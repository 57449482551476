<card>
  <card-body>
    <div class="container">
      <form [formGroup]="historyForm">
        <div class="form-group">
          <p class="h6" translate>manage_acccess_request.edit.history.title</p>
          <input
            type="text"
            formControlName="title"
            class="form-control col-md-12"
          />
        </div>
        <div class="form-group">
          <p class="h6" translate>
            manage_acccess_request.edit.history.message
          </p>
          <textarea
            formControlName="description"
            class="form-control"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group text-right">
          <button
            class="btn btn-primary"
            (click)="submit()"
            translate
            [disabled]="setDisabledButton()"
          >
            manage_acccess_request.edit.history.send
          </button>
        </div>
      </form>
    </div>
  </card-body>
</card>
